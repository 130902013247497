import { Trans } from 'react-i18next';
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { MDBDropdownLink } from 'mdb-react-ui-kit';
import { DataStorage } from "../../enum/dataStorage";
import Document from "../../models/document";
import { Rest } from "../../rest";
import TableComponent from './tableComponent';
import { DocumentStatus } from "../../enum/documentStatus";
import Utils from "../../utils";
import { LogType } from "../../enum/logType";
import { ObjectType } from "../../enum/objectType";
import { AppContext } from '../../context/app.context';
import LogService from '../../service/logService';
import { AccessKey } from '../../enum/accessKey';
import React from 'react'
import { SignerContacts } from '../../models/signer.contact'

class DocumentTable extends TableComponent {
    static contextType = AppContext;

    constructor() {
        super();
        this.Id = 'document-table-component';
        this.state.sortBy = 'id';
        this.state.visibleColumns = ['id', 'property_original_file', 'property_responsible', 'property_signer', 'property_signer_counterparty', 'status', 'download'];
        var currentDefinition = this;

        /*
        this.Header.push({
            getHeaderObject: function () {
                return currentDefinition.getSelectAllCell();
            },
            getCellObject: function (item) {
                return currentDefinition.getSelectRowCell(item.Id);
            }
        });*/

        this.Header.push({
            getCellObject: function (item) {
                if (currentDefinition.showActions(item)) {
                    return currentDefinition.getDropdownCell(currentDefinition.getDropdownActions(item));
                } else {
                    return <td className="align-middle px-0"></td>
                }
            }
        });

        this.Header.push({
            text_id: 'field-id',
            field: 'id',
            sortable: true,
            getCellObject: function (item) {
                return (<td className="align-middle">
                    <Link to={`/document/${item.Id}`}>{item.Id}</Link>
                </td>);
            }
        });


        this.Header.push({
            text_id: 'field-document-title',
            field: 'property_original_file',
            getCellObject: function (item) {
                return (
                    <td className="align-middle">
                        {item.OriginalFile &&
                            <Link to={`/document/${item.Id}`}>{item.OriginalFile.name}</Link>
                        }
                    </td>
                );
            }
        });

        this.Header.push({
            text_id: 'field-responsible',
            field: 'property_responsible',
            getCellObject: function (item) {
                return (
                    <td className="align-middle">
                        {item.Responsible &&
                            item.Responsible.LinkView
                        }
                    </td>
                );
            }
        });

        this.Header.push({
            text_id: 'field-signer',
            field: 'property_signer',
            getCellObject: function (item) {
                return (
                    <td className="align-middle">
                        {item.Signer &&
                            item.Signer.LinkView
                        }
                    </td>
                );
            }
        });

        this.Header.push({
            text_id: 'field-signer-counterparty',
            field: 'property_signer_counterparty',
            getCellObject: function (item) {
                return (
                    <td className="align-middle">
                        {item.SignerCounterparty?.length > 0 &&
                          (item.SignerCounterparty.length === 1
                            ? (<>
                              <a href={item.SignerCounterparty[0].Link} target="_blank">{item.SignerCounterparty[0].FullName}</a>
                              {currentDefinition.getSignerStatus(item, item.SignerCounterparty[0])}
                          </>)
                            : (<ul style={{padding: 0}}>{item.SignerCounterparty.map((cp) => (<li className="mb-2">
                              <a href={cp.Link} target="_blank">{cp.FullName}</a>
                              {currentDefinition.getSignerStatus(item, cp)}
                            </li>))}</ul>))
                        }
                    </td>
                );
            }
        });

        this.Header.push({
            text_id: 'field-status',
            field: 'status',
            getCellObject: function (item) {
                return (
                    <td className="align-middle">
                        <div className={Utils.getDocumentStatusClass(item.Status)}><Trans>document-status-{item.Status}</Trans></div>
                        <small><Trans>{item.StatusComment}</Trans></small>
                        {item.SignDateValue > 0 &&
                            <div>{moment(item.SignDateValue).format('L LTS')}</div>
                        }
                    </td>
                );
            }
        });

        this.Header.push({
            text_id: 'field-communication-crm',
            field: 'property_deal',
            getCellObject: function (item) {
                return (
                    <td className="align-middle">
                        {item.Deal &&
                            <a href={item.Deal.Link} target="_blank">{item.Deal.Name}</a>
                        }
                    </td>
                );
            }
        });

        this.Header.push({
            text_id: 'btn-download',
            field: 'download',
            getCellObject: function (item) {
                return (
                    <td className="align-middle">
                        {item.OriginalFile &&
                            <div>
                                <a href={item.OriginalFile.link} target="_blank"><i className="fas fa-download me-2"></i><Trans>field-original-file</Trans></a>
                            </div>
                        }
                        {item.SignedFile &&
                            <div>
                                <a href={item.SignedFile.link} target="_blank"><i className="fas fa-download me-2"></i><Trans>field-signed-file</Trans></a>
                            </div>
                        }
                        {item.AgreementFile &&
                            <div>
                                <a href={item.AgreementFile.link} target="_blank"><i className="fas fa-download me-2"></i><Trans>field-agreement-file</Trans></a>
                            </div>
                        }
                        {item.ReportFile &&
                            <div>
                                <a href={item.ReportFile.link} target="_blank"><i className="fas fa-download me-2"></i><Trans>field-report-file</Trans></a>
                            </div>
                        }
                    </td>
                );
            }
        })

        /*
        this.GroupActions.push({
            id: 'delete',
            action: this.groupDelete,
            textId: 'btn-delete'
        });*/
    }

    loadData = async () => {
        const currentDefinition = this;
        const sortBy = currentDefinition.state.sortBy.toUpperCase();
        const sortOrder = currentDefinition.state.sortOrder.toUpperCase();
        var requestData = {
            ENTITY: DataStorage.document,
            SORT: {},
            filter: {},
            start: currentDefinition.getOffset()
            //take: currentDefinition.getStep()
        };
        requestData.SORT[sortBy] = sortOrder;
        currentDefinition.applyFilter(requestData.filter);

        if (currentDefinition.props.dealId) {
            requestData.filter.PROPERTY_DEAL = currentDefinition.props.dealId;
        }

        if (!currentDefinition.context.access(AccessKey.settings)) {
            let currentUserId = currentDefinition.context.getCurrentUser().Id;
            requestData.filter[1] = {
                LOGIC: 'OR',
                PROPERTY_RESPONSIBLE: currentUserId,
                PROPERTY_SIGNER: currentUserId,
                ['%PROPERTY_OBSERVERS']: `_${currentUserId}_`,
            }
        }

        requestData.filter.ACTIVE = 'Y';
        try {
            const result = await Rest.callMethod('entity.item.get', requestData);
            const items = result.items.map(x => new Document(x));
            const userIds = [];
            let contactIds = [];
            const dealIds = [];
            const fileIds = [];
            items.forEach((item) => {
                userIds.push(item.ResponsibleId);
                userIds.push(item.SignerId);
                if (item.SignerCounterpartiesList.length) {
                    contactIds = contactIds.concat(item.SignerCounterpartiesList)
                } else {
                    contactIds.push(item.SignerCounterpartyId);
                }
                dealIds.push(item.DealId);
                fileIds.push(item.OriginalFileId);
                fileIds.push(item.SignedFileId);
                fileIds.push(item.AgreementFileId);
                fileIds.push(item.ReportFileId);
            });
            const files = await Rest.getDealFiles(fileIds);
            const deals = await Rest.getDeals(dealIds);
            const users = await Rest.getUsers(userIds);
            const contacts = await Rest.getContacts(contactIds);

            items.forEach(item => {
                if (users[item.ResponsibleId]) {
                    item.Responsible = users[item.ResponsibleId];
                }
                if (users[item.SignerId]) {
                    item.Signer = users[item.SignerId];
                }
                if (item.SignerCounterpartiesList.length) {
                    item.SignerCounterparty =  item.SignerCounterpartiesList.map((cp) => contacts[cp]).filter((cp) => !!cp);
                } else if (contacts[item.SignerCounterpartyId]) {
                    item.SignerCounterparty = [contacts[item.SignerCounterpartyId]];
                }
                if (deals[item.DealId]) {
                    item.Deal = deals[item.DealId];
                }
                if (files[item.OriginalFileId]) {
                    item.setOriginalFileObject(files[item.OriginalFileId]);
                }
                if (files[item.SignedFileId]) {
                    item.setSignedFileObject(files[item.SignedFileId]);
                }
                if (files[item.AgreementFileId]) {
                    item.setAgreementFileObject(files[item.AgreementFileId]);
                }
                if (files[item.ReportFileId]) {
                    item.setSignedFileObject(files[item.ReportFileId]);
                }
            });
            currentDefinition.printRows(items, result.total);
        }
        catch (err) {
            currentDefinition.loadDataError(err);
        }
        finally {
            currentDefinition.loadDataAlways();
        }
    }

    clickEdit = (e, item) => {
        if (e) e.preventDefault();
        window.location.href = `/document/${item.Id}`;
    }

    clickSend = async (e, item) => {
        if (e) e.preventDefault();

        const currentUserAuthData = Rest.getAuth();
        if (!currentUserAuthData)
            return;

        try {
            currentUserAuthData.documentId = item.Id;
            const result = await axios.post('/document/send', currentUserAuthData);
            const data = result.data;
            this.loadData();
        }
        catch (err) {
            console.error('sendDocument err', Utils.getError(err));
        }
    }

    clickDelete = (e, item) => {
        const _ = this;
        if (e) e.preventDefault();
        Rest.callMethod('entity.item.delete', { ENTITY: DataStorage.document, ID: item.Id }).then(() => {
            new LogService(LogType.DocumentDelete, _.context.getCurrentUser().Id, item.Id, ObjectType.document).add();
            _.loadData();
        }).catch(err => {
            console.error('clickDelete document', err);
        });
    }

    clickQueueDelete = async (e, item) => {
        const _ = this;
        if (e) e.preventDefault();

        const currentUserAuthData = Rest.getAuth();
        if (!currentUserAuthData)
            return;

        try {
            currentUserAuthData.documentId = item.Id;
            const result = await axios.post('/document/delete', currentUserAuthData);
            const data = result.data;
            console.log('QueueDelete  result', data);
            this.loadData();
        }
        catch (err) {
            console.error('QueueDelete  err', Utils.getError(err));
        }
    }

    clickTest = async (e, item) => {
        const _ = this;
        if (e) e.preventDefault();

        const currentUserAuthData = Rest.getAuth();
        if (!currentUserAuthData)
            return;

        try {
            currentUserAuthData.documentId = item.Id;
            const result = await axios.post('/test', currentUserAuthData);
            const data = result.data;
            console.log('clickTest  result', data);
            //this.loadData();
        }
        catch (err) {
            console.error('clickTest  err', Utils.getError(err));
        }
    }

    getDropdownActions = (item) => {
        const currentDefinition = this;
        const btnEdit = (
            <MDBDropdownLink tag="button" onClick={(e) => currentDefinition.clickEdit(e, item)}>
                <Trans>btn-edit</Trans>
            </MDBDropdownLink>
        );
        const btnDelete = (
            <MDBDropdownLink tag="button" onClick={(e) => currentDefinition.clickDelete(e, item)}>
                <Trans>btn-delete</Trans>
            </MDBDropdownLink>
        );

        if (item.Status === DocumentStatus.draft) {
            const btnSend = (
                <MDBDropdownLink tag="button" onClick={(e) => currentDefinition.clickSend(e, item)}>
                    <Trans>btn-send-sign</Trans>
                </MDBDropdownLink>
            );
            return [btnEdit, btnSend, btnDelete];
        }

        if (item.Status === DocumentStatus.queue || item.Status === DocumentStatus.sendError) {
            const btnQueueDelete = (
                <MDBDropdownLink tag="button" onClick={(e) => currentDefinition.clickQueueDelete(e, item)}>
                    <Trans>btn-queue-delete</Trans>
                </MDBDropdownLink>
            );
            return [btnEdit, btnQueueDelete, btnDelete];
        }

        /*
        if (item.Status === DocumentStatus.emailComplitedDoc) {
            const btnTest = (
                <MDBDropdownLink tag="button" onClick={(e) => currentDefinition.clickTest(e, item)}>
                    Test
                </MDBDropdownLink>
            );
            return [btnEdit, btnTest, btnDelete];
        }*/
        return [btnEdit, btnDelete];
    }

    groupDelete = (callback) => {
        const _ = this;
        console.log('groupDelete');
        /*
        $.post('/firmware/delete', {id: _.state.selectedItems}, function(result) {
            if(result.error) {
                console.error(result.error_description);
                callback();
            }
            else {
                callback();
                _.loadData();
            }
        }).fail(err => {
            console.error(err);
            callback();
        });*/
    }

    showActions(item) {
        const currentUserId = this.context.getCurrentUser().Id;
        return this.context.access(AccessKey.settings) || item.ResponsibleId === currentUserId || item.SignerId === currentUserId;
    }

    getSignerStatus(item, cp) {
        const status = item.SignerStatuses[cp.getContactByType(item.SignerContacts[cp.ID] ?? SignerContacts.EMAIL.id)];
        return status ? <div style={{lineHeight: 1}} className={Utils.getSignerStatusClass(status)}><small><Trans>signer-status-{status}</Trans></small></div> : null;
    }
}

export default DocumentTable;